import {
  InvoiceModel,
  SignatureViewModel,
  TransactionSearchResult,
  TransactionSummaryViewModel,
  SearchParameters,
  TransactionsUpdateReceiverRequest,
  TransactionDeliveryViewModel,
} from 'common/models/BookingTypes';
import { message, notification } from 'antd';
import { GetNotificationProps } from 'common/utils/utility';
import { APIResponseCode, MessageDisplay } from 'common/constants';
import { APIResponse, GetData, PostData } from 'common/utils/jsonFetch';
import { Validator } from 'common/validators/validators';
import { KFSEndpoints } from 'common/constants/KFSEndpoints';
import { TransactionActions } from '../store/TransactionSlice';
import { ReceiverActions } from '@features/Receiver';
import { BarcodeRequestModel, BoxOrderViewModel } from 'common/models';
import { BookingActions } from '@features/Booking';
import { HandleErrorMessage } from '@features/Common';
import { TransactionStatusDetailsViewModel } from 'common/models/TransactionStatus';
import { RemarksRequestViewModel, RemarksResponse } from 'common/models/RemarksTypes';

export const GetTransactionById = (id: string) => {
  return async function SubmitBookingThunk(dispatch) {
    const methodName = 'GetTransactionById';
    try {
      const response = await PostData<APIResponse>(KFSEndpoints.Transaction_GetTransactionSummary, id);
      new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);
      const data: TransactionSummaryViewModel = response.parsedBody?.Results;

      dispatch(TransactionActions.SetTransactionSummary(data));
      dispatch(TransactionActions.SetORDateSend(data.Invoice?.ORDateSend));
    } catch (e) {
      notification.error(GetNotificationProps(MessageDisplay.GENERIC_TECHNICAL_ERROR));
    }
  };
};

export const GetInvoiceByRefId = (id: string) => {
  return async function SubmitBookingThunk(dispatch) {
    const methodName = 'GetInvoiceByRefId';
    try {
      dispatch(TransactionActions.submitStatus());
      const response = await PostData<APIResponse>(KFSEndpoints.Invoice_GetByRefId, id);
      new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);
      const data: InvoiceModel = response.parsedBody?.Results;
      dispatch(TransactionActions.submitStatusSucceeded());
      dispatch(TransactionActions.SetInvoice(data));
    } catch (e) {
      // dispatch(BookingActions.submitStatusRejected(HandleErrorMessage(methodName, e)));
      notification.error(GetNotificationProps(MessageDisplay.GENERIC_TECHNICAL_ERROR));
    }
  };
};

export const SaveBookingInvoice = (request: InvoiceModel) => async (dispatch) => {
  const methodName = 'SaveBookingInvoice';
  try {
    dispatch(TransactionActions.submitStatus());
    const response = await PostData<APIResponse>(KFSEndpoints.Invoice_SaveBookingInvoice, request); // Adjust the endpoint accordingly
    new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);
    const transactionsId: string | undefined = request?.TransactionsId;

    // Check if transactionsId is defined before passing it to the function
    if (transactionsId !== undefined) {
      dispatch(GetInvoiceByRefId(transactionsId));
    }
    dispatch(TransactionActions.submitStatusSucceeded());
  } catch (e) {
    // dispatch(BookingActions.submitStatusRejected(HandleErrorMessage(methodName, e)));
    notification.error(GetNotificationProps(MessageDisplay.GENERIC_TECHNICAL_ERROR));
  }
};

export const SaveSignature = (request: SignatureViewModel) => async (dispatch) => {
  const methodName = 'SaveSignature';
  try {
    dispatch(TransactionActions.submitStatus());
    const response = await PostData<APIResponse>(KFSEndpoints.Transaction_SaveSignature, request); // Adjust the endpoint accordingly
    new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);
    dispatch(TransactionActions.submitStatusSucceeded());
  } catch (e) {
    // dispatch(BookingActions.submitStatusRejected(HandleErrorMessage(methodName, e)));
    notification.error(GetNotificationProps(MessageDisplay.GENERIC_TECHNICAL_ERROR));
  }
};

export const UpdateReceiverTransaction = (request: TransactionsUpdateReceiverRequest) => async (dispatch) => {
  const methodName = 'UpdateReceiver';
  try {
    dispatch(TransactionActions.submitStatus());
    const response = await PostData<APIResponse>(KFSEndpoints.Transaction_UpdateReceiver, request);
    new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);
    dispatch(TransactionActions.submitStatusSucceeded());
    message.success('Receiver updated successfully');
    dispatch(ReceiverActions.SetSelectedId(undefined));
  } catch (e) {
    notification.error(GetNotificationProps(MessageDisplay.GENERIC_TECHNICAL_ERROR));
  }
};

export const GetTransactionByRefNo = (refNo: string) => {
  return async function SubmitBookingThunk(dispatch) {
    const methodName = 'GetTransactionByRefNo';
    try {
      dispatch(TransactionActions.resetState());
      dispatch(TransactionActions.submitStatus());
      const response = await PostData<APIResponse>(KFSEndpoints.Transaction_GetTransactionByRefNo, refNo);
      new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);
      dispatch(TransactionActions.SetFilterSearchParameters({ RefNo: refNo }));
      dispatch(TransactionActions.SetTransactionType(response.parsedBody?.Results.Type));
      dispatch(TransactionActions.SetSelectedTransId(response.parsedBody?.Results.Id));

      dispatch(TransactionActions.submitStatusSucceeded());
    } catch (e) {
      message.error('Record not found', 2);
      dispatch(TransactionActions.submitStatusRejected());
    }
  };
};

export const SearchBy = (param: SearchParameters) => {
  return async function SearchByThunk(dispatch) {
    const methodName = 'SearchBy';
    try {
      dispatch(TransactionActions.submitStatus());
      const response = await PostData<APIResponse>(KFSEndpoints.Transaction_SearchBy, param);
      new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);

      const data: TransactionSearchResult[] = response.parsedBody?.Results;
      dispatch(TransactionActions.SetTransactionSearchResult(data));
      dispatch(TransactionActions.submitStatusSucceeded());
    } catch (e) {
      message.error('Record not found', 2);
      dispatch(TransactionActions.submitStatusRejected());
    }
  };
};

export const SearchByDownload = () => {
  return async function SearchByThunk(dispatch) {
    const methodName = 'SearchByDownload';
    try {
      dispatch(TransactionActions.submitStatus());
      const response = await GetData<APIResponse>('/Common/Generate');
      // new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);
      // new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);

      const blob = new Blob([response.parsedBody?.Results], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      // console.log(blob);
      return blob;
    } catch (e) {
      message.error('Record not found', 2);
      dispatch(TransactionActions.submitStatusRejected());
    }
  };
};

export const DeleteBooking = (refId: string) => {
  return async function DeleteBookingThunk(dispatch) {
    const methodName = 'DeleteBooking';
    try {
      dispatch(TransactionActions.resetState());
      dispatch(TransactionActions.submitStatus());
      const response = await PostData<APIResponse>(KFSEndpoints.Transaction_Void, refId);
      new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);
      dispatch(TransactionActions.submitStatusSucceeded());
      message.success('Booking deleted successful');
    } catch (e) {
      message.error('Delete encountered an error', 2);
      dispatch(TransactionActions.submitStatusRejected());
    }
  };
};

export const SendOR = (refId: string) => {
  return async function SendORThunk(dispatch) {
    const methodName = 'SendOR';
    try {
      const response = await PostData<APIResponse>(KFSEndpoints.Invoice_SendOR, refId);
      new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);
      message.success('Receipt has been sent successfully');
      dispatch(TransactionActions.SetORDateSend(response.parsedBody?.Results));
    } catch (e) {
      message.error('Sending Receipt encountered an error', 2);
    }
  };
};

export const UpdateOrderBox = (order: BoxOrderViewModel) => {
  return async function UpdateOrderBoxThunk(dispatch) {
    const methodName = 'UpdateOrderBox';
    try {
      dispatch(TransactionActions.resetState());
      dispatch(TransactionActions.submitStatus());
      const response = await PostData<APIResponse>(KFSEndpoints.OrderBox_UpdateStatus, order);
      new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);
      dispatch(TransactionActions.submitStatusSucceeded());
      message.success('Updated successful');
    } catch (e) {
      message.error('Updating encountered an error', 2);
      dispatch(TransactionActions.submitStatusRejected());
    }
  };
};

export const GetTransactionDelivery = (id: string) => {
  return async function SubmitBookingThunk(dispatch) {
    const methodName = 'GetTransactionDelivery';
    try {
      const response = await PostData<APIResponse>(KFSEndpoints.Transaction_GetTransactionDelivery, id);
      new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);
      const data: TransactionDeliveryViewModel = response.parsedBody?.Results;

      dispatch(TransactionActions.SetTransactionDelivery(data));
    } catch (e) {
      notification.error(GetNotificationProps(MessageDisplay.GENERIC_TECHNICAL_ERROR));
    }
  };
};

export const UpdateToDeliver = (request: BarcodeRequestModel) => async (dispatch) => {
  const methodName = 'UpdateToDeliver';
  try {
    dispatch(BookingActions.submitStatus());
    const response = await PostData<APIResponse>(KFSEndpoints.Transaction_UpdateToDelivery, request);
    new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);
    dispatch(BookingActions.submitStatusSucceededNoPayload());
  } catch (e) {
    dispatch(BookingActions.submitStatusRejected(HandleErrorMessage(methodName, e)));
    notification.error(GetNotificationProps(MessageDisplay.GENERIC_TECHNICAL_ERROR));
    throw e; // Rethrow the error to propagate it further if needed
  }
};

export const UpdateTransactionStatusOutForDelivery =
  (request: TransactionStatusDetailsViewModel) => async (dispatch) => {
    const methodName = 'UpdateTransactionStatusOutForDelivery';
    try {
      dispatch(BookingActions.submitStatus());
      const response = await PostData<APIResponse>(KFSEndpoints.TransactionStatusDetails_Save, request);
      new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);
      dispatch(BookingActions.submitStatusSucceededNoPayload());
      if (request.Id) {
        message.success('Out for delivery details updated successfully');
      } else {
        message.success('Out for delivery details saved successfully');
      }
    } catch (e) {
      dispatch(BookingActions.submitStatusRejected(HandleErrorMessage(methodName, e)));
      notification.error(GetNotificationProps(MessageDisplay.GENERIC_TECHNICAL_ERROR));
      throw e; // Rethrow the error to propagate it further if needed
    }
  };

export const GetTransactionStatusDetails = (id: string) => {
  return async function GetTransactionStatusDetails(dispatch) {
    const methodName = 'GetTransactionStatusDetails';
    try {
      const response = await PostData<APIResponse>(KFSEndpoints.TransactionStatusDetails_Get, id);
      new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);
      const data: TransactionStatusDetailsViewModel[] = response.parsedBody?.Results;

      dispatch(TransactionActions.SetTransactionStatusDetailsViewModelList(data));
    } catch (e) {
      notification.error(GetNotificationProps(MessageDisplay.GENERIC_TECHNICAL_ERROR));
    }
  };
};

export const SaveRemarks = (request: RemarksRequestViewModel) => async (dispatch) => {
  const methodName = 'SaveRemarks';
  try {
    dispatch(BookingActions.submitStatus());
    const response = await PostData<APIResponse>(KFSEndpoints.Remarks_Save, request);
    new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);
    dispatch(BookingActions.submitStatusSucceededNoPayload());

    message.success('Note saved successfully');
    dispatch(GetRemarks(request.TransactionsId));
  } catch (e) {
    dispatch(BookingActions.submitStatusRejected(HandleErrorMessage(methodName, e)));
    notification.error(GetNotificationProps(MessageDisplay.GENERIC_TECHNICAL_ERROR));
    throw e; // Rethrow the error to propagate it further if needed
  }
};

export const GetRemarks = (id: string) => {
  return async function GetRemarks(dispatch) {
    const methodName = 'GetRemarks';
    try {
      const response = await PostData<APIResponse>(KFSEndpoints.Remarks_Get, id);
      new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);
      const data: RemarksResponse[] = response.parsedBody?.Results;

      dispatch(TransactionActions.SetTransactionRemarks(data));
    } catch (e) {
      notification.error(GetNotificationProps(MessageDisplay.GENERIC_TECHNICAL_ERROR));
    }
  };
};
