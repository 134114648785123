import { PATHS } from '@routing/routes';
import { Col, Row, Form, Button } from 'antd';
import {
  CodeSandboxOutlined,
  UserOutlined,
  UsergroupAddOutlined,
  FileSearchOutlined,
  LockOutlined,
  BarcodeOutlined,
  FormOutlined,
  GiftOutlined,
  TableOutlined,
  CalculatorOutlined,
  MergeCellsOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';

import { BookingActions, IsFirstTransaction } from '@features/Booking';
import { useDispatch, useSelector } from 'react-redux';
import { SenderDetailsActions } from '@features/SenderDetails';
import { ReceiverActions } from '@features/Receiver';
import { Constants, CountryWorklocation, FilterType, Roles } from 'common/constants';
import { TransactionActions } from '@features/Transaction';
import { BarcodeActions } from '@features/Barcode';
import { AccountSelector } from '@features/Account';
import { TrackingActions } from '@features/Tracking';
import { OrderBoxActions } from '@features/OrderBox';
import { EmployeeActions } from '@features/Employee';
import { commonAction } from '@features/Common/store/commonSlice';
import { ContainerActions } from '@features/Container';
import { BackOfficePH } from './BackOfficePH';
import { StoresActions } from '@features/Stores';

export const Home = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const accountState = useSelector(AccountSelector);

  useEffect(() => {
    dispatch(BookingActions.resetState());
    dispatch(SenderDetailsActions.resetState());
    dispatch(ReceiverActions.resetState());
    dispatch(BarcodeActions.resetState());
    dispatch(TransactionActions.resetState());
    dispatch(TrackingActions.resetState());
    dispatch(OrderBoxActions.resetState());
    dispatch(EmployeeActions.resetState());
    dispatch(commonAction.ResetGlobalState());
    dispatch(ContainerActions.resetState());
    dispatch(StoresActions.resetState());
    dispatch(SenderDetailsActions.SetFirstSender(false));
    dispatch(BookingActions.SetIsFromSignupKFS(false));
    if (accountState.RoleName === null || accountState.RoleName === undefined) {
      history.push(PATHS.Login.path);
    }
  }, []);

  const onSendaBox = async () => {
    await dispatch(IsFirstTransaction());
    dispatch(BookingActions.SetIsSendABox(true));
    history.push('/sender/listing');
  };

  const onKFSSendaBox = async () => {
    dispatch(BookingActions.SetIsSendABox(true));
    dispatch(BookingActions.SetIsFromSignupKFS(true));
    history.push(PATHS.Sender.path);
  };

  const onSenderList = () => {
    dispatch(BookingActions.SetIsSendABox(false));
    history.push('/sender/listing');
  };

  const onTransactionRedirect = (
    filterType: typeof FilterType.RefNo | typeof FilterType.Email | typeof FilterType.Date
  ) => {
    dispatch(TransactionActions.SetFilterType(filterType));
    history.push('/transaction');
  };

  return (
    <>
      <Row justify="center" align="middle">
        <Col xs={20} sm={16} md={10} lg={8} xl={6}>
          {accountState.RoleName == Roles.BackOffice && accountState.WorkLocationCountry == CountryWorklocation.PH && (
            <BackOfficePH />
          )}
          {accountState.WorkLocationCountry != CountryWorklocation.PH && (
            <>
              {accountState.RoleName == Roles.Agent && (
                <>
                  <Form.Item>
                    <Button
                      type="default"
                      icon={<CodeSandboxOutlined />}
                      size="large"
                      block
                      className="bold left-icon-button main-menu-option site-form-item-icon"
                      onClick={onKFSSendaBox}
                    >
                      <div className="menu-small-320">{Constants.KFSBOX_BookPickupKFSHeader}</div>
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="default"
                      icon={<CodeSandboxOutlined />}
                      size="large"
                      block
                      className="bold left-icon-button main-menu-option site-form-item-icon"
                      onClick={() => history.push(PATHS.OrderEmtpyBox.path)}
                    >
                      <div className="menu-small-320">Order an empty box</div>
                    </Button>
                  </Form.Item>
                </>
              )}
              {accountState.RoleName == Roles.Customer && (
                <>
                  <Form.Item>
                    <Button
                      type="default"
                      icon={<CodeSandboxOutlined />}
                      size="large"
                      block
                      className="bold left-icon-button main-menu-option site-form-item-icon"
                      onClick={onSendaBox}
                    >
                      <div className="menu-small-320">{Constants.KFSBOX_BookPickupHeader}</div>
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="default"
                      icon={<CodeSandboxOutlined />}
                      size="large"
                      block
                      className="bold left-icon-button main-menu-option site-form-item-icon"
                      onClick={() => history.push(PATHS.SenderListingOrderBox.path)}
                    >
                      <div className="menu-small-320">Order an empty box</div>
                    </Button>
                  </Form.Item>
                </>
              )}
              {accountState.RoleName != Roles.Warehouse && (
                <Form.Item>
                  <Button
                    type="default"
                    icon={<FormOutlined />}
                    size="large"
                    block
                    className="bold left-icon-button main-menu-option site-form-item-icon"
                    onClick={() => history.push('/tracking')}
                  >
                    <div className="menu-small-320">Tracking</div>
                  </Button>
                </Form.Item>
              )}
              {accountState.RoleName == Roles.Customer && (
                <>
                  <Form.Item>
                    <Button
                      type="default"
                      icon={<CodeSandboxOutlined />}
                      size="large"
                      block
                      className="bold left-icon-button main-menu-option site-form-item-icon"
                      onClick={() => history.push('/booking/listing')}
                    >
                      <div className="menu-small-320">Transaction summary</div>
                    </Button>
                  </Form.Item>
                </>
              )}
              {accountState.RoleName != Roles.Customer && accountState.RoleName != Roles.Warehouse && (
                <>
                  <Form.Item>
                    <Button
                      type="default"
                      icon={<FileSearchOutlined />}
                      size="large"
                      block
                      className="bold left-icon-button main-menu-option site-form-item-icon"
                      onClick={() => onTransactionRedirect(FilterType.RefNo)}
                    >
                      <div className="menu-small-320 textLeft">
                        Transaction search
                        <div className="menu-small">(Reference, barcode no)</div>
                      </div>
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="default"
                      icon={<FileSearchOutlined />}
                      size="large"
                      block
                      className="bold left-icon-button main-menu-option site-form-item-icon"
                      onClick={() => onTransactionRedirect(FilterType.Email)}
                    >
                      <div className="menu-small-320 ">
                        Transaction search
                        <div className="menu-small">(Email)</div>
                      </div>
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="default"
                      icon={<FileSearchOutlined />}
                      size="large"
                      block
                      className="bold left-icon-button main-menu-option site-form-item-icon"
                      onClick={() => onTransactionRedirect(FilterType.Date)}
                    >
                      <div className="menu-small-320 textLeft">
                        Transaction search
                        <div className="menu-small">(Date, status, state)</div>
                      </div>
                    </Button>
                  </Form.Item>
                </>
              )}
              {accountState.RoleName == Roles.Customer && (
                <>
                  <Form.Item>
                    <Button
                      type="default"
                      icon={<UserOutlined />}
                      size="large"
                      block
                      className="bold left-icon-button main-menu-option site-form-item-icon"
                      onClick={onSenderList}
                    >
                      <div className="menu-small-320">Sender details</div>
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="default"
                      icon={<UsergroupAddOutlined />}
                      size="large"
                      block
                      className="bold left-icon-button main-menu-option site-form-item-icon"
                      onClick={() => history.push('/receiver/listing')}
                    >
                      <div className="menu-small-320">Receiver details</div>
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="default"
                      icon={<GiftOutlined />}
                      size="large"
                      block
                      className="bold left-icon-button main-menu-option site-form-item-icon"
                      onClick={() => history.push(PATHS.Referral.path)}
                    >
                      <div className="menu-small-320">Refer a friend</div>
                    </Button>
                  </Form.Item>
                </>
              )}
              {accountState.RoleName == Roles.Warehouse && (
                <Form.Item>
                  <Button
                    type="default"
                    icon={<TableOutlined />}
                    size="large"
                    block
                    className="bold left-icon-button main-menu-option site-form-item-icon"
                    onClick={() => history.push(PATHS.ContainerListing.path)}
                  >
                    <div className="menu-small-320">Container management</div>
                  </Button>
                </Form.Item>
              )}
              {(accountState.RoleName == Roles.Admin ||
                (accountState.RoleName == Roles.BackOffice &&
                  accountState.WorkLocationCountry == CountryWorklocation.PH)) && (
                <>
                  <Form.Item>
                    <Button
                      type="default"
                      icon={<MergeCellsOutlined />}
                      size="large"
                      block
                      className="bold left-icon-button main-menu-option site-form-item-icon"
                      onClick={() => history.push(PATHS.InterStateListing.path)}
                    >
                      <div className="menu-small-320">Interstate transfer</div>
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="default"
                      icon={<TableOutlined />}
                      size="large"
                      block
                      className="bold left-icon-button main-menu-option site-form-item-icon"
                      onClick={() => history.push(PATHS.ContainerListing.path)}
                    >
                      <div className="menu-small-320">Container management</div>
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="default"
                      icon={<BarcodeOutlined />}
                      size="large"
                      block
                      className="bold left-icon-button main-menu-option site-form-item-icon"
                      onClick={() => history.push('/barcode/generate')}
                    >
                      <div className="menu-small-320">Generate barcode</div>
                    </Button>
                  </Form.Item>
                </>
              )}

              {(accountState.RoleName == Roles.Admin ||
                accountState.Username == 'agent_vic@kfs.com' ||
                accountState.Username == 'agent_syd@kfsbox.com' ||
                accountState.Username == 'mayette@kfs.com' ||
                accountState.Username == 'erick@kfs.com' ||
                accountState.Username == 'manny@kfs.com' ||
                accountState.Username == 'michelle@kfs.com' ||
                (accountState.RoleName == Roles.BackOffice &&
                  accountState.WorkLocationCountry == CountryWorklocation.PH)) && (
                <>
                  <Form.Item>
                    <Button
                      type="default"
                      icon={<CalculatorOutlined />}
                      size="large"
                      block
                      className="bold left-icon-button main-menu-option site-form-item-icon"
                      onClick={() => history.push(PATHS.DeliveryAreaRate.path)}
                    >
                      <div className="menu-small-320">Rate calculator</div>
                    </Button>
                  </Form.Item>
                </>
              )}
            </>
          )}
          {accountState.RoleName == Roles.Admin && (
            <>
              <Form.Item>
                <Button
                  type="default"
                  icon={<TableOutlined />}
                  size="large"
                  block
                  className="bold left-icon-button main-menu-option site-form-item-icon"
                  onClick={() => history.push(PATHS.StoresIncentive.path)}
                >
                  <div className="menu-small-320">Store management</div>
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  type="default"
                  icon={<FormOutlined />}
                  size="large"
                  block
                  className="bold left-icon-button main-menu-option site-form-item-icon"
                  onClick={() => history.push(PATHS.TrackingManagement.path)}
                >
                  <div className="menu-small-320">Tracking management</div>
                </Button>
              </Form.Item>
            </>
          )}
          <Form.Item>
            <Button
              type="default"
              icon={<LockOutlined />}
              size="large"
              block
              className="bold left-icon-button main-menu-option site-form-item-icon"
              onClick={() => history.push('account/changepassword')}
            >
              <div className="menu-small-320">Change password</div>
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
