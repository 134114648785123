import KFSLogo from '@assets/images/kfs-logo.png';
export const kfsLogo = KFSLogo;

import underconstructionLogo from '@assets/images/underconstruction.png';
export const underconstruction = underconstructionLogo;

import webpageHeader from '@assets/images/webpageheader.png';
export const webpageheader = webpageHeader;

import phoneIcon from '@assets/images/phoneIcon.png';
export const phoneIconExport = phoneIcon;

import boxIcon from '@assets/images/boxIcon.png';
export const boxIconExport = boxIcon;

import letsTalkIcon from '@assets/images/letsTalkIco.png';
export const letsTalkIconExport = letsTalkIcon;

import rizal from '@assets/images/rizal.png';
export const mnlIcon = rizal;

import riceTerrace from '@assets/images/riceTerrace.png';
export const luzonIcon = riceTerrace;

import cross from '@assets/images/cross.png';
export const visIcon = cross;

import mindanaoicon from '@assets/images/mindanaoicon.png';
export const minIcon = mindanaoicon;

export { default as containerIcon } from '@assets/images/kfsboxcontainer.png';
export { default as businessFreighIcon } from '@assets/images/businessFreigh2.jpg';

export { default as fbicon } from '@assets/images/fbicon.png';
export { default as contactusheader } from '@assets/images/contactusheader.png';

export { default as referFamily } from '@assets/images/referFamily.png';
export { default as sharelove } from '@assets/images/sharelove.png';
export { default as getRewarded } from '@assets/images/getRewarded.png';
export { default as ReferReward } from '@assets/images/ReferReward.png';
export { default as referred } from '@assets/images/referred.png';
export { default as loyalty } from '@assets/images/loyalty.png';
export { default as loyalty2 } from '@assets/images/Loyalty2.png';
export { default as KFSboxSize } from '@assets/images/KFSboxSize.jpg';
export { default as KFSBoxDesign } from '@assets/images/KFSBoxDesign.jpg';
export { default as KFSBoxDesign2 } from '@assets/images/KFSBoxDesign2.jpg';
export { default as KFSpartnerstoreicon } from '@assets/images/partnerstoreicon.png';

export { default as KFSStoreN1 } from '@assets/images/KFS_Store_N1.png';
export { default as KFSStoreN2 } from '@assets/images/IMG_6441.png';
export { default as KFSStoreN3 } from '@assets/images/KFS_Store_N3.png';
export { default as KFS_Store_MelBalikbayan } from '@assets/images/KFS_Store_MelBalikbayan.jpeg';

export { default as KFSStoreW1 } from '@assets/images/KFS_Store_W1.png';
export { default as KFSStoreW2 } from '@assets/images/KFS_Store_W2.png';
export { default as KFSStoreW3 } from '@assets/images/KFS_Store_W3.png';
export { default as KFSStoreW4 } from '@assets/images/KFS_Store_W4.png';
export { default as KFSStoreW5 } from '@assets/images/KFS_Store_W5.png';
export { default as KFSStoreW6 } from '@assets/images/KFS_Store_W6.png';

export { default as KFSStoreSE1 } from '@assets/images/KFS_Store_SE1.png';
export { default as KFSStoreSE2 } from '@assets/images/KFS_Store_SE2.png';
export { default as KFSStoreSE3 } from '@assets/images/KFS_Store_SE3.png';
export { default as KFSStoreSE4 } from '@assets/images/KFS_Store_SE4.png';
export { default as KFSStoreSE5 } from '@assets/images/KFS_Store_SE5.png';
export { default as KFSStoreSE6 } from '@assets/images/KFS_Store_SE6.png';

export { default as mnlMel } from '@assets/images/mnlMel.png';
export { default as KFS_Luzon_Mel } from '@assets/images/KFS_Luzon_Mel.png';
export { default as KFS_Vis_Mel } from '@assets/images/KFS_Vis_Mel.png';
export { default as KFS_Min_Mel } from '@assets/images/KFS_Min_Mel.png';

export { default as kfsmnlsyd } from '@assets/images/kfs_mnl_syd.png';
export { default as KFS_Luzon_Syd } from '@assets/images/KFS_Luzon_Syd.png';
export { default as KFS_Vis_Syd } from '@assets/images/KFS_Vis_Syd.png';
export { default as KFS_Min_Syd } from '@assets/images/KFS_Min_Syd.png';

export { default as KFS_Store_Syd1 } from '@assets/images/KFS_Store_Syd1.png';
export { default as KFS_Store_Syd2 } from '@assets/images/KFS_Store_Syd2.png';
export { default as KFS_Store_Syd3 } from '@assets/images/KFS_Store_Syd3.png';

export { default as KFS_Store_Syd4 } from '@assets/images/KFS_Store_Syd4.png';
export { default as KFS_Store_Syd5 } from '@assets/images/KFS_Store_Syd5.png';
export { default as KFS_Store_Syd6 } from '@assets/images/KFS_Store_Syd6.png';

export { default as KFS_Store_Syd7 } from '@assets/images/KFS_Store_Syd7.png';
export { default as KFS_Store_Syd8 } from '@assets/images/KFS_Store_Syd8.png';
export { default as KFS_Store_Syd9 } from '@assets/images/KFS_Store_Syd9.png';

export { default as KFS_Store_Syd10 } from '@assets/images/KFS_Store_Syd10.png';
export { default as KFS_Store_Syd11 } from '@assets/images/KFS_Store_Syd11.png';
export { default as KFS_Store_Syd13 } from '@assets/images/KFS_Store_Syd13.jpg';

export { default as KFS_Store_Syd14 } from '@assets/images/KFS_Store_Syd14.jpeg';
export { default as KFS_Store_Syd15 } from '@assets/images/KFS_Store_Syd15.jpg';
export { default as KFS_Store_SE16 } from '@assets/images/KFS_Store_SE16.jpeg';
export { default as QRlogo } from '@assets/images/qrLogo.png';

export { default as KFS_Store_Syd12 } from '@assets/images/KFS_Store_Syd12.png';
export { default as KFS_Store_Syd16 } from '@assets/images/KFS_Store_Syd16.jpeg';
export { default as KFS_Store_Syd17 } from '@assets/images/KFS_Store_Syd17.jpeg';

export { default as KFS_Store_Mel } from '@assets/images/KFS_Store_Mel.jpeg';

export { default as KFS_Store_Bendigo1 } from '@assets/images/KFS_Store_Bendigo1.png';
export { default as KFS_Store_Bendigo2 } from '@assets/images/KFS_Store_Bendigo2.png';

export { default as KFS_Store_Warnambool2 } from '@assets/images/KFS_Store_Warnambool2.png';
export { default as KFS_Store_Warnambool3 } from '@assets/images/KFS_Store_Warnambool3.png';
